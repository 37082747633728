import {FormField} from '../../../form/object/form-field';
import {FormStructure} from '../../../form/object/form-structure';

export class FilterFormFieldFactory
{
    /*public*/
    createFormField(container, column, value, elasticsearch = false)
    {
        let formField = null;

        if (!column.searchable) {
            return;
        }

        const property = column.searchProperty || column.property;

        switch (column.filter || column.type) {

            case 'email':
            case 'collection':
            case 'string':
            case 'translatable':
            case 'multiline':
            case 'file-path':
                formField = new FormField(
                    container,
                    {type: 'text', property, groupClass: 'form-group-sm', originalType: column.filter || column.type},
                    column.property,
                    value
                );
                break;

            case 'phone':
                formField = new FormField(
                    container,
                    {type: 'text', property, groupClass: 'form-group-sm'},
                    column.property,
                    value
                );
                formField.filterType = 'phone';
                break;

            // @fixme Address handling must be generalized
            case 'address':
                formField = new FormField(
                    container,
                    {type: 'text', property: elasticsearch ? property : '$text', groupClass: 'form-group-sm'},
                    elasticsearch ? column.property : '$text',
                    value
                );
                break;

            case 'money':
                formField = new FormStructure(
                    container,
                    {
                        property,
                        fields: [
                            {
                                type: 'number',
                                property: 'from',
                                groupClass: 'form-group-sm',
                                label: 'filter.number.from',
                            },
                            {
                                type: 'number',
                                property: 'to',
                                groupClass: 'form-group-sm',
                                label: 'filter.number.to',
                            }
                        ],
                        type: 'money-range'
                    },
                    column.property,
                    value
                );
                break;

            case 'float':
                formField = new FormStructure(
                    container,
                    {
                        property,
                        fields: [
                            {
                                type: 'number',
                                property: 'from',
                                groupClass: 'form-group-sm',
                                label: 'filter.number.from',
                            },
                            {
                                type: 'number',
                                property: 'to',
                                groupClass: 'form-group-sm',
                                label: 'filter.number.to',
                            }
                        ],
                        type: 'float-range'
                    },
                    column.property,
                    value
                );
                break;

            case 'percentage':
                formField = new FormStructure(
                    container,
                    {
                        property,
                        fields: [
                            {
                                type: 'number',
                                subType: 'percentage',
                                property: 'from',
                                groupClass: 'form-group-sm',
                                label: 'filter.number.from',
                            },
                            {
                                type: 'number',
                                subType: 'percentage',
                                property: 'to',
                                groupClass: 'form-group-sm',
                                label: 'filter.number.to',
                            }
                        ],
                        type: 'percentage-range'
                    },
                    column.property,
                    value
                );
                break;

            case 'integer':
                formField = new FormStructure(
                    container,
                    {
                        property,
                        fields: [
                            {
                                type: 'number',
                                subType: 'integer',
                                property: 'from',
                                groupClass: 'form-group-sm',
                                label: 'filter.number.from',
                            },
                            {
                                type: 'number',
                                subType: 'integer',
                                property: 'to',
                                groupClass: 'form-group-sm',
                                label: 'filter.number.to',
                            }
                        ],
                        type: 'int-range'
                    },
                    column.property,
                    value
                );
                 break;

            case 'tags':
                formField = new FormStructure(
                    container,
                    {
                        property,
                        fields: [
                            {
                                type: 'tags',
                                property: 'include',
                                groupClass: 'form-group-sm',
                                label: 'filter.tags.include',
                                options: {
                                    modelId: column?.options?.modelId,
                                    tagsProperty: property
                                }
                            },
                            {
                                type: 'tags',
                                property: 'exclude',
                                groupClass: 'form-group-sm',
                                label: 'filter.tags.exclude',
                                options: {
                                    modelId: column?.options?.modelId,
                                    tagsProperty: property
                                }
                            }
                        ],
                        type: 'tags'
                    },
                    column.property,
                    value
                );
                break;

            case 'physical-size':
                formField = new FormStructure(
                    container,
                    {
                        property,
                        fields: [
                            {
                                type: 'number',
                                property: 'from',
                                groupClass: 'form-group-sm',
                                label: 'filter.physical-size.from',
                            },
                            {
                                type: 'number',
                                property: 'to',
                                groupClass: 'form-group-sm',
                                label: 'filter.physical-size.to',
                            }
                        ],
                        type: 'physical-size'
                    },
                    column.property,
                    value
                );
                break;

            case 'reference':
                formField = new FormField(
                    container,
                    {
                        type: 'choice',
                        modelId: column.options.modelId,
                        hideCreateAction: true,
                        multiple: true,
                        property: column.property,
                        groupClass: 'form-group-sm',
                        options: {
                            hideAssignToMe: true
                        },
                    },
                    property,
                    value
                );
                break;

            case 'boolean':
                formField = new FormField(
                    container,
                    {
                        type: 'choice',
                        choices: [
                            {
                                value: 'yes',
                                label: 'sio.yes'
                            },
                            {
                                value: 'no',
                                label: 'sio.no'
                            }
                        ],
                        hideCreateAction: true,
                        property,
                        groupClass: 'form-group-sm'
                    },
                    column.property,
                    value
                );
                formField.filterType = 'boolean';
                break;

            case 'choice':
                formField = new FormField(
                    container,
                    {
                        type: 'choice',
                        set: column.options.choice.set,
                        choices: column.options.choice.choices,
                        hideCreateAction: true,
                        multiple: true,
                        property,
                        groupClass: 'form-group-sm',
                    },
                    column.property,
                    value
                );
                break;

            case 'dueDate':
            case 'age':
            case 'date':
                formField = new FormStructure(
                    container,
                    {
                        property,
                        fields: [
                            {
                                type: 'date',
                                subType: 'date',
                                withTime: false,
                                property: 'fromDate',
                                groupClass: 'form-group-sm',
                                label: 'filter.date.from',
                                options: {
                                    filterMode: true,
                                    timezone: column?.options?.timezone,
                                }
                            },
                            {
                                type: 'date',
                                subType: 'date',
                                withTime: false,
                                property: 'toDate',
                                groupClass: 'form-group-sm',
                                label: 'filter.date.to',
                                options: {
                                    filterMode: true,
                                    timezone: column?.options?.timezone,
                                }
                            }
                        ],
                        type: 'date-range'
                    },
                    column.property,
                    value
                );
                break;
        }

        return formField;
    }
}
