import {customElement, bindable, inject} from "aurelia-framework";
import {I18N} from 'aurelia-i18n';
import "./input.less";

@customElement('sio-text-input')
@inject(I18N)
export class TextInput {
    @bindable field = null;
    @bindable({defaultBindingMode: 2}) value = null;

    constructor(i18n) {
        this.i18n = i18n;
    }

    hasExactlyTwoFields() {
        const swapFields = this.field?.formService?.config?.swapFields || [];
        return swapFields.some(pair =>
            (pair.field0 === this.field.property || pair.field1 === this.field.property)
        );
    }

    getSwapTooltip() {
        const swapFields = this.field?.formService?.config?.swapFields || [];
        const swapPair = swapFields.find(pair =>
            pair.field0 === this.field.property || pair.field1 === this.field.property
        );

        if (!swapPair) {
            return '';
        }

        // Find the other field in the swap pair
        const otherFieldProperty = swapPair.field0 === this.field.property ? swapPair.field1 : swapPair.field0;
        if (!otherFieldProperty) {
            return '';
        }

        // Translate the tooltip using the label of the other field
        const otherField = document.querySelector(`[name="${otherFieldProperty}"]`);
        return otherField
            ? this.i18n.tr('sio.form.swap-tooltip', { field: otherField.label || 'another field' })
            : this.i18n.tr('sio.form.swap-tooltip-generic');
    }

    swapValues() {
        const swapFields = this.field?.formService?.config?.swapFields || [];
        const swapPair = swapFields.find(pair =>
            pair.field0 === this.field.property || pair.field1 === this.field.property
        );

        if (!swapPair) {
            console.error(`No valid swap pair found for field: ${this.field.property}`);
            return;
        }

        // Determine the two fields to swap
        const [field1Property, field2Property] =
            swapPair.field0 === this.field.property
                ? [swapPair.field0, swapPair.field1]
                : [swapPair.field1, swapPair.field0];

        const field1 = document.querySelector(`[name="${field1Property}"]`);
        const field2 = document.querySelector(`[name="${field2Property}"]`);

        if (!field1 || !field2) {
            console.error('One or both fields in the swap pair are missing from the DOM.');
            return;
        }

        // Swap the values
        const tempValue = field1.value;
        field1.value = field2.value;
        field2.value = tempValue;

        // Dispatch events to notify Aurelia of changes
        ['change', 'input'].forEach(eventType => {
            field1.dispatchEvent(new Event(eventType, { bubbles: true }));
            field2.dispatchEvent(new Event(eventType, { bubbles: true }));
        });
    }
}
